import React from 'react'
import Layout from "../components/layout";
import { Helmet } from 'react-helmet';
import Testimonials from '../components/testimonials';

function Over() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Over - Bart Sallé, freelance webdeveloper";
  const description = "Als freelance webdeveloper kan ik met trots zeggen dat ik van mijn grootste hobby mijn beroep heb kunnen maken. Goede service leveren staat altijd voorop!";
  const url = "https://www.bartsalle.nl/over/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="over" />
      </Helmet>
        <div className="container">
          <h1>Over</h1>
          <p>Als freelance webdeveloper kan ik met trots zeggen dat ik van mijn grootste hobby mijn beroep heb kunnen maken. Naast het werken aan diverse projecten voor kleine en grote opdrachtgevers houd ik continu de laatste ontwikkelingen bij. Leergierigheid en nieuwsgierigheid zijn twee van mijn belangrijkste eigenschappen.</p><p>Daarnaast durf ik te zeggen dat ik over het algemeen een meer dan goede verstandhouding met mijn klanten heb. Goede service leveren staat altijd voorop. Ik ga daarom heel ver om jouw project te laten slagen!</p>
          <h2>Testimonials</h2>
          <p>Ik laat liever anderen aan het woord over mij. Mensen voor wie ik inmiddels een dienst bewezen heb.</p>
          <Testimonials />
        </div>
    </Layout>
  )
}

export default Over