import React, { Component } from "react"
import Slider from "react-slick"
import { StaticImage } from "gatsby-plugin-image"
import "../css/slick/slick.css"
import "../css/slick/slick-theme.css"

export default class Testimonials extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1000,
              settings: {
                dots: true,
                arrows: false
              }
            }]
      };
      return (
        <div className="testimonials-component">
          <Slider {...settings}>
            <div>
                <p>Bart is een zeer gedreven en gemotiveerde programmeur en ontwikkelaar die veel kennis heeft met betrekking tot webwinkels. Naast de technische kennis is Bart ook goed in staat een fraai ontwerp te maken!</p>
                <StaticImage src="../images/testimonials/robert.jpg" alt="Robert Vlug" layout="fixed" width={65} height={65} placeholder="none" className='round' />
                <h3>Robert Vlug, auteur van “Een eigen webwinkel voor Dummies”</h3>
            </div>
            <div>
                <p>Programmeur met gedegen advies. Het grote voordeel van Bart is dat hij meedenkt en kritische vragen stelt. Bart is dus niet alleen een programmeur, maar ook een adviseur. Hij neemt daarbij het kostenaspect en de snelheid van oplevering mee in zijn aanbevelingen. Bart komt zijn afspraken na en levert kwaliteit binnen de afgesproken planning.</p>
                <StaticImage src="../images/testimonials/octave.png" alt="Octave Regout" layout="fixed" width={65} height={65} placeholder="none" className='round' />
                <h3>Octave Regout, directeur ContactDays.nl</h3>
            </div>
            <div>
                <p>Bart is niet alleen een expert op het gebied van webdesign en CMS, hij is ook een heel aangenaam en geduldig persoon die doorgaat tot een probleem is opgelost en tegelijkertijd je meer inzichten geeft in de ingewikkelde materie van het webdesign.</p>
                <StaticImage src="../images/testimonials/atmani.png" alt="Atmani Blok" layout="fixed" width={65} height={65} placeholder="none" className='round' />
                <h3>Atmani Blok</h3>
            </div>
            <div>
                <p>Bart Sallé is een fantastische webdesigner. De site voor ons bedrijf is precies wat we willen. Wat echter veel belangrijker is, is dat Bart betrouwbaar is, eerlijk zegt wat voor jou als klant het beste is en altijd zijn uiterste best doet om het beste resultaat te bereiken. Een echte topper binnen zijn vakgebied.</p>
                <StaticImage src="../images/testimonials/michel.png" alt="Michel van den Bos" layout="fixed" width={65} height={65} placeholder="none" className='round' />
                <h3>Michel van den Bos, Het Vertelspel</h3>
            </div>
            <div>
                <p>Bart Sallé; geweldige webdesigner, staat altijd klaar met goed advies, neemt de tijd en is makkelijk bereikbaar! En een geweldig fotograaf!! Professioneel en super met de bewerking, ieder plaatje is een kunstwerk!</p>
                <StaticImage src="../images/testimonials/maggy.png" alt="Maggy Vredeveld" layout="fixed" width={65} height={65} placeholder="none" className='round' />
                <h3>Maggy Vredeveld, Maggy Meets Marilyn</h3>
            </div>
            <div>
                <p>Wij zijn heel blij dat wij bij Bart Sallé terecht zijn gekomen voor het ontwikkelen van onze nieuwe webwinkel. Wij hebben Bart leren kennen als een professionele, betrouwbare, flexibele en enthousiaste vakman. Niets is teveel en alles kan. Wij zijn meer dan tevreden met de geweldige samenwerking en het resultaat.</p>
                <StaticImage src="../images/testimonials/mariella.jpg" alt="Mariella Paul" layout="fixed" width={65} height={65} placeholder="none" className='round' />
                <h3>Mariella Paul, Craftsupplies</h3>
            </div>
            <div>
                <p>Bart Sallé staat in mijn ogen voor een goed product. Mijn website is precies wat het moet zijn. Overzichtelijk en voorzien van een goede vormgeving! Ik ben meer dan tevreden!</p>
                <StaticImage src="../images/testimonials/jimmyg.png" alt="Jimmy G" layout="fixed" width={65} height={65} placeholder="none" className='round' />
                <h3>Jimmy G, Hip Hop Artist</h3>
            </div>
            <div>
                <p>Bart werkt snel en efficiënt en denkt ook verder dan het gestelde probleem. Bart reageert bijna onmiddellijk op de gestelde vragen. Hij is een specialist in zijn vak.</p>
                <StaticImage src="../images/testimonials/guy.png" alt="Guy Hacha" layout="fixed" width={65} height={65} placeholder="none" className='round' />
                <h3>Guy Hacha, WebXafari S.L.</h3>
            </div>
          </Slider>
        </div>
      );
    }
  }